/*---------------------------------------------
Conversion Area
---------------------------------------------*/
.cvarea{
    background-color: #0269b2;
    margin-top: 20px;
    @include SP{
        margin-bottom: 20px;
    };
    position: relative;

    @include PC{
        display: flex;
        &:after{
            content: "";
            display: block;
            clear: both;
        }
    };

    &_inner{
        width: 50%;
        padding: {
            top: 30px;
            // bottom: 45px;
            bottom: 105px;
        };
        position: relative;
        @include SP{
            width: 100%;
            padding: 30px;
        };
    }
    &_left{
        @include PC{
            padding: {
                left: 70px;
                right: 80px;
            };
        };

        @include screen(1001px, 1200px){
            padding-right: 40px;
        };

        a:before{
            content: '\f02d';
        }
    }
    &_right{
        @include PC{
            padding: {
                left: 60px;
                right: 60px;
            };
            border-left: #FFF solid 1px;
        };
        @include screen(1001px, 1200px){
            padding-right: 20px;
        };
        @include SP{
            border-top: #FFF solid 1px;
        };

        a:before{
            content: '\f82d';
        }
    }

    .title{
        font-size: 18px;
        color: #FFF;
        font-weight: 500;
        margin-bottom: 5px;
        display: inline-block;
        font-family: 'Noto Sans JP', sans-serif;
        @include SP{
            line-height: 1.277777778em;
        };
    }

    p{
        font-size: 16px;
        line-height: 1.5em;
        color: #FFF;
        letter-spacing: 0.04em;
        padding: 10px 0 25px;
        @include SP{
            font-size: 14px;
            line-height: 1.642857143em;
            padding: 10px 0 0;
        };
    }

    a {
        max-width: 270px;
        width: 100%;
        height: 60px;
        display: inline-block;
        border: #FFF solid 1px;
        color: #FFF;
        text-align: center;
        padding-top: 18px;
        transition: all .3s;
        font-weight: bold;
        // position: relative;

        @include PC{
            position: absolute;
            bottom: 45px;
        };


        @include max-screen(1060px){
            font-size: 14px;
        };

        @include SP{
            position: relative;
            display: block;
            height: 50px;
            font-size: 18px;
            padding-top: 10px;
            margin-top: 10px;
            margin: 10px auto 0;
            max-width: inherit;
        };

        &:hover{
            color: #0269b2;
            background-color: #FFF;
            &:before, &:after{
                color: #0269b2;
            }
        }

        &:before, &:after{
            font-family: 'Font Awesome 5 Free';
            font-weight: 300;
            color: #FFF;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:before{
            font-size: 24px;
            left: 20px;
            @include SP{
                font-size: 18px;
            };
        }

        &:after{
            content: '\f105';
            font-size: 20px;
            right: 20px;
            @include SP{
                right: 16px;
            };
        }
    }
}
