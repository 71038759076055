/*---------------------------------------------
Side Button
---------------------------------------------*/
.side_btn {
    width: 80px;
    height: 80px;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #00439b;
    z-index: 1001;

    @include SP2{
        width: 50px;
        height: 50px;
        right: 50px;
    };
    > a {
        display: block;
        height: 100%;
        @include PC{
             transition: all .3s;
            &:hover{
                background-color: #FFF;
                i, span{
                    color: #00439b;
                }
            }
        };

        i {
            font-family: "Font Awesome 5 Free";
            font-weight: 300;
            font-style: normal;
            font-size: 24px;
            color: #fff;
            display: block;
            text-align: center;
            padding: 15px 0 10px;
            @include SP2{
                font-size: 20px;
            };
        }
        span {
            font-size: 14px;
            color: #FFF;
            text-align: center;
            display: block;
        }
    }
}
