/*---------------------------------------------
Header
---------------------------------------------*/
.header{
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: scroll;
    z-index: 999;
    background-color: #eee;
    -ms-overflow-style: none;    /* IE, Edge 対応 */
    scrollbar-width: none;       /* Firefox 対応 */
    &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
        display:none;
    }
    @include SP2{
        width:  100%;
        height: 50px;
        overflow: initial;
        background-color: #FFF;
        z-index: 1001;
    };

    &_inner{
        background-color: #FFF;
    }

    a{
        display: block;
        transition: all .3s;
        height: 100%;
        font-family: 'Noto Sans JP', sans-serif;
    }

    .logo{
        @include SP2{
            width: calc(100% - 100px);
        };
        > a{
            padding: 20px 15px;
            @include SP2{
                padding: 12px 13px 0;
            };
            img{
                max-width: 210px;
                width: 100%;
                @include SP2{
                    // max-width: 247.5px;
                    max-width: 300px;
                };
            }
            &:hover{
                opacity: .7;
            }
        }
    }

    .hamburger{
        width: 50px;
        height: 50px;
        background-color: #000;
        position: absolute;
        right: 0;
        top: 0;
        span{
            width: 18px;
            height: 2px;
            background-color: #FFF;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transition: all .3s;
            font-family: 'Noto Sans JP', sans-serif;

            &:nth-child(1){
                top: 17px;
            }
            &:nth-child(2){
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            &:nth-child(3){
                bottom: 17px;
            }
        }

        &.open{
            span{
                &:nth-child(1){
                    top: 24.5px;
                    transform: translateX(-50%) rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:nth-child(3){
                    bottom: 23.5px;
                    transform: translateX(-50%) rotate(-45deg);
                }
            }
        }
    }


    span{
        font-size: 15px;
        font-family: 'Noto Sans JP', sans-serif;
    }

    .nav{
        @include SP2{
            display: none;
            height: calc(100vh - 50px);
            width: 100%;
            position: fixed;
            top: 50px;
            left: 0;
            z-index: 1001;
            background-color: #000;
            overflow-y: auto;
            padding: 30px 20px 100px;

            a, span{
                color: #FFF;
                font-size: 14px;
            }
        };

        &.open{
            display: block;
        }

    }

    &_list{
        @include SP2{

        };

        > li{
            height: 45px;
            position: relative;
            > a{
                padding: 9px 15px 0;
                @include ie{
                    // padding-top: 15px;
                };
            }
            @include PC2{
                &:hover{
                    > a{
                        background-color: #d3dfee;
                    }

                    span, &:after{
                        color: #00439b;
                    }
                }
            };

            &.toggle > .sub_menu{
                display: none;
                position: fixed;
                left: 240px;
                top: 0;
                background-color:#d3dfee;
                width: 260px;
                padding-top: 94px;
                height: 100%;

                @include SP2{
                    height: auto;
                    position: static;
                    width: 100%;
                    padding-top: 0;
                    background-color: transparent;
                };

                a{
                    @include PC2{
                        transition: opacity .3s;
                        &:hover{
                            color: #00439b;
                        }
                    };
                }

                dl{
                    dt{
                        font-size: 16px;
                        font-weight: 700;
                        border-bottom: solid 2px #214e6f;
                        @include SP2{
                            display: none;
                        };

                        > a{
                            padding: 20px 10px 15px 18px;
                            position: relative;

                            &:after{
                                content: '\f105';
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 500;
                                font-size: 20px;
                                position: absolute;
                                top: 56%;
                                right: 16px;
                                transform: translateY(-50%);
                                color: #000;
                            }
                            &:hover:after{
                                color: #00439b;
                            }
                        }
                    }
                    dd{
                        font-size: 15px;
                        @include SP2{
                            margin: -10px 0 15px;
                        };

                        > ul{
                            @include SP2{
                                margin-left: 10px;
                            };
                            > li{
                                height: 45px;
                                @include SP2{
                                    height: auto;
                                };
                                > a{
                                    padding: 12px 10px 0 18px;
                                    position: relative;
                                    font-family: 'Noto Sans JP', sans-serif;
                                    @include SP2{
                                        padding: 8px 20px;
                                    };

                                    @include PC2{
                                        &:after{
                                            content: '\f105';
                                            font-family: 'Font Awesome 5 Free';
                                            font-weight: 300;
                                            font-size: 20px;
                                            position: absolute;
                                            top: 50%;
                                            right: 18px;
                                            transform: translateY(-50%);
                                            color: #000;
                                        }
                                        &:hover:after{
                                            color: #00439b;
                                        }
                                    };
                                }
                            }
                        }
                    }
                }
            }

            @include PC2{
                &.toggle:hover > .sub_menu{
                    display: block;
                }
            };

            @include PC2{
                &:before{
                content: '';
                width: 4px;
                height: 100%;
                background-color: #235dac;
                position: absolute;
                left: 0;
                top: 0;
            }
            };

            border-bottom: #cccccc solid 1px;

            &:first-child{
                border-top: #cccccc solid 1px;
            }

            &.arrow:after{
                content     : '\f105';
	            font-family : 'Font Awesome 5 Free';
	            font-weight : 300;
                font-size: 20px;
                position: absolute;
                top: 50%;
                right: 18px;
                transform: translateY(-50%);

                @include ie{
                    right: 10px;
                }
            }

            &.toggle:after{
                content     : '\f0c9';
	            font-family : 'Font Awesome 5 Free';
	            font-weight : 300;
                font-size: 16px;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                @include ie{
                    right: 8px;
                }
            }

            @include SP2{
                height: auto;
                 > a{
                   padding: 15px 10px;
                }
                 &.toggle{
                    &:after{
                        content: none;
                    }
                    .expand{
                        width: 47px;
                        height: 47px;
                        position: absolute;
                        right: 0;
                        top: 0;

                        &:before, &:after{
                            content: '';
                            background-color: #FFF;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        }

                        &:before{
                            width: 12.5px;
                            height: 2px;

                        }

                        &:after{
                            width: 2px;
                            height: 12.5px;
                            transition: height .3s;
                        }
                    }

                    .expanded:after{
                        height: 0px;
                    }
                }

                &.arrow:after{
                    color: #FFF;
                }
            };
        }
    }

    .list_typeB{
        margin-bottom: 1px;


        > a{
            display: flex;
            align-items: center;
            position: relative;

            .img{
                width: 35px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                @include SP2{
                    width: 40px;
                };
            }
            span{
                color: #FFF;
                padding-left: 15px;
                @include SP2{
                    font-size: 15px;
                };
            }
        }
    }

    &_lock{
        height: 60px;
        background-color: #093776;
        @include SP2{
            margin-top: 20px;
        };

        > a:after{
            content     : '\f30d';
            color: #FFF;
            font-family : 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 20px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }

        &:hover{
            background-color: #d3dfee;
            > a > span, a:after{
                color: #00439b;
            }
        }
    }

    &_recruit{
        height: 60px;
        background-color: #666666;

        > a:after{
            content     : '\f14c';
            color: #FFF;
            font-family : 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 16px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }

        &:hover{
            background-color: #FFF;
            > a > span, a:after{
                color: #666666;
            }
        }
    }
    &_contact{
        height: 40px;
        background-color: #666666;

        > a:after{
            content     : '\f105';
            font-family : 'Font Awesome 5 Free';
            color: #FFF;
            font-weight : 300;
            font-size: 20px;
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
        }

        &:hover{
            background-color: #FFF;
            > a > span, a:after{
                color: #666666;
            }
        }
    }

    .list_typeC{
        @include SP2{
            // border-top: #ccc solid 1px;
        };

        > a{
            padding: {
                top: 15px;
                left: 15px;
            }
            position: relative;

            &:after{
                font-family : 'Font Awesome 5 Free';
                font-weight : 300;
                position: absolute;
                right: 20px;
                @include SP2{
                    right: 12px;
                };
            }

            @include PC2{
                 &:hover{
                    background-color: #d3dfee;
                    span, p, &:after{
                        color: #00439b;
                    }
                }
            };

            @include ie{
                padding-top: 17px;
            }
        }
    }

    &_mail{
        border-bottom: #ccc solid 1px;
        @include SP2{
            margin-top: 25px;
            border-top: #ccc solid 1px;
            height: 50px;
        };

        > a{
            padding: {
                right: 90px;
                bottom: 15px;
            }

            &:after{
                content     : '\f674';
                font-size: 40px;
                top: 20px;
                @include SP2{
                    font-size: 16px;
                };
            }
        }

        .txt{
            p{
                margin-top: 8px;
                font-size: 11px;
                text-align: justify;
                line-height: normal;
                font-family: 'Noto Sans JP', sans-serif;
                @include SP2{
                    display: none;
                };
            }
        }
    }
    &_info{
        height: 60px;
        margin: 0;
        @include SP2{
            height: 50px;
            border-bottom: #ccc solid 1px;
        };
        > a:after{
            content     : '\f865';
            top: 50%;
            transform: translateY(-50%);
            font-size: 25px;
            @include SP2{
                font-size: 16px;
            };
        }
    }

    &_business{
        padding: 18px;
        background-color: #eee;
        @include SP2{
            background-color: transparent;
            padding: 0;

        };
        a{
            display: block;
            width: 100%;
            @include SP2{
                max-width: 200px;
                margin: 25px auto 10px;
            };
            img{
                display: block;
                width: 100%;
            }

            &:hover{
                opacity: .7;
            }
        }
    }

    .nav_close{
        width: 50px;
        height: 50px;
        margin-left: auto;
        position: relative;
        right: -20px;
        display: block;
        &:before{
            content : '\f00d';
            font-family : 'Font Awesome 5 Free';
            font-weight : 300;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            font-size: 20px;
            color: #FFF;
        }
    }
}
