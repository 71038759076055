/*---------------------------------------------
Local Nav
---------------------------------------------*/
.local-nav{
    background-color: #303030;
    @include SP{
        margin: 0 0 5px;
    };

    &_system .inner a{
        width: calc(100% / 8);
        // width: calc(100% / 7);
        @include SP{
            width: 25%;
        };
    }

    &_company .inner a{
        width: calc(100% / 7);
        @include SP{
            width: 25%;
        };
    }

    &.typeB{
        margin-top: -20px;
        .inner{
            padding-right: 0;
            padding-left: 0;
        }
        @include SP{
            display: none;
        };
    }

    .inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include SP{
            padding: 15px 0;
            flex-wrap: wrap;
            justify-content: flex-start;
        };

        a{
            font-size: 14px;
            letter-spacing: 0.04em;
            line-height: 1.428571429em;
            color: #FFF;
            text-align: center;
            position: relative;
            padding: 17px 0;
            height: 100%;
            min-height: 75px;

            @include ie{
                padding: 20px 0 14px;
            };

            &:not(.active){

                &:before{
                    transition: all .3s;
                }
            }

            &.center{
                padding-top: 27px;
                @include SP{
                    padding-top: 8px;
                };
                @include ie{
                    padding-top: 30px;
                };
            }

            @include SP{
                font-size: 12px;
                line-height: 1.333333333em;
                padding: 0;
                min-height: 32px;

                &:nth-child(-n + 4){
                    margin-bottom: 25px;
                }
                &:nth-child(9),&:nth-child(10),&:nth-child(11),&:nth-child(12){
                    margin-top: 25px;
                }
                &:nth-child(4n + 1){
                    &:after{
                        content: none;
                    }
                }
            };

            &:after{
                content: '';
                height: 40px;
                width: 1px;
                background-color: #FFF;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:before{
                content: '';
                width: 40px;
                height: 6px;
                background-color: #0269b2;
                position: absolute;
                bottom: 5px;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                @include SP{
                    height: 3px;
                    width: 20px;
                };
            }

            @include PC{
                &:nth-child(-n + 1){
                    &:after{
                        content: none;
                    }
                }
                &:hover{
                    color: #bce5ff;
                    &:before{
                        opacity: 1;
                        bottom: -3px;
                    }
                }
            };

            &.active{
                color: #bce5ff;
                pointer-events: none;
                &:before{
                    opacity: 1;
                    bottom: -3px;
                    @include SP{
                        bottom: -15px;
                    };
                }

                &.typeB{
                    pointer-events: painted;
                }
            }
        }
    }
}
