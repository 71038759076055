/*---------------------------------------------
Footer
---------------------------------------------*/
.footer{
    padding-left: 240px;
    background-color: #000;
    z-index: 1000;
    position: relative;

    @include max-screen(1400px){
        padding-left: 0px;
    };
    @include SP{
        padding-left: 0;
        margin-bottom: 33px;
    };
    * {
        color: #FFF;
    }
    a{
        position: relative;

        @include PC{
            transition: opacity .3s;
            &:hover{
                opacity: .7;
            }
        };
    }

    &_inner{
        padding: 40px 50px 25px 30px;
        @include SP{
            padding: 25px 20px 40px;
        };
    }

    &_top{
        border-left: #FFF solid 1px;
        padding: 5px 0 5px 20px;
        @include SP{
            padding: 0;
        };

        a{
            font-size: 16px;
            line-height: 1.875em;
            font-weight: bold;
            @include SP{
                font-size: 14px;
                line-break: 1.071428571em;
                padding: 5px 0 5px 15px;
                display: block;
            };
        }
    }

    &_main{
        display: flex;
        margin: 40px 0 20px;
        @include SP{
            display: block;
            margin: 25px 0;
        };

        > ul {
            width: 33%;
            border-left: #FFF solid 1px;
            padding: 15px 20px;
            &:last-child{
                border-right: #FFF solid 1px;
            }
            @include SP{
                width: 100%;
                border-left: none;
                padding: 0;
                &:last-child{
                    border-right: none;
                }

                &:first-child{
                    border-top: #FFF solid 1px;
                }
            };

            li{
                font-weight: bold;
                line-height: 1.785714286em;
                font-size: 14px;

                > a{
                    position: relative;
                }

                &.icon a{

                    &:after{
                        position: relative;
                        color: #FFF;
                        font-family : 'Font Awesome 5 Free';
                        font-weight: 300;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                &.icon_private a:after{
                    content: '\f30d';
                    right: -20px;
                    font-size: 16px;
                }

                &.icon_recruit a:after{
                    content: '\f14c';
                    right: -20px;
                    font-size: 16px;
                }

                &.typeB{
                    font-size: 13px;
                    font-weight: normal;
                }

                @include SP{
                    border-bottom: #FFF solid 1px;
                    position: relative;

                    > a{
                        display: block;
                        padding: 10px 15px;
                    }
                    &.typeB{
                        border-bottom: none;
                        font-size: 12px;
                        margin: 5px 15px 0;

                        > a{
                            display: inline;
                            padding: 0;
                        }
                    }

                    &.typeB_first{
                        margin-top: 20px;
                    }

                    &.icon a:after{

                    }

                    &.icon_arrow a:after{
                        content: '\f105';
                        right: 15px;
                        font-size: 20px;
                    }

                    &.icon_private a:after{
                        right: 12px;
                    }

                    &.icon_recruit a:after{
                        right: 12px;
                    }

                    &.footer_expand{
                        position: relative;
                        > a{
                            width: calc(100% - 40px);
                        }
                        .expand_btn{
                            width: 40px;
                            height: 45px;
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 0;

                            &:before, &:after{
                                content: '';
                                background-color: #FFF;
                                position: absolute;
                                transform: translateY(-50%) translateX(-50%);
                                top: 50%;
                                left: 50%;
                            }
                            &:before{
                                height: 15px;
                                width: 2px;
                                transition: height .3s;
                            }
                            &:after{
                                height: 2px;
                                width: 15px;
                            }
                            &.expanded{
                                &:before{
                                    height: 0;
                                }
                            }
                        }
                    }

                };

                > ul{
                    @include SP{
                        display: none;
                        font-weight: normal;
                        margin-bottom: 15px;
                        margin-top: -5px;
                    };
                    > li{
                        font-weight: normal;
                        border-bottom: none;
                        @include SP{
                            padding-left: 20px;
                        };
                        > a{
                            padding: 2px 15px;
                        }
                    }
                }
            }
        }
    }
    &_bottom{
        display: flex;
        justify-content: space-between;

        @include max-screen(900px){
            align-items: flex-end;
        }

        @include SP{
            display: block;
        };

        &_address{
            font-size: 14px;
            line-height: 1.428571429em;
            @include max-screen(1200px){
                font-size: 12px;
            }

            @include max-screen(1000px){
                font-size: 10px;
            }

            &_title{
                font-weight: bold;
                @include max-screen(1000px){
                   font-size: 12px;
                }
                @include SP{
                    font-size: 14px;
                    margin-bottom: 5px;
                };
            }
            p{
                @include SP{
                    font-size: 12px;
                    line-height: 1.75em;
                };
            }
            a:hover{
                border-bottom: none;
                opacity: .7;
            }
        }

        &_copyright{
            padding-right: 15.5vw;
            margin-top: 10px;

            p{
                font-size: 11px;
                line-height:  1.428571429em;;
                letter-spacing: 0;
            }

            @include max-screen(1200px){
                padding-right: 100px;
                p{
                    font-size: 10px;
                    transform-origin: right;
                    transform: scale(0.9);
                }

            };
            @include max-screen(900px){
                padding-right: 80px;
                p{
                    white-space: nowrap;
                    transform-origin: right;
                    transform: scale(0.7);
                }
            }

            @include SP{
                padding-right: 0;
                margin-top: 20px;
                p{
                    font-size: 11px;
                    line-height: 1.363636364em;;
                    white-space: normal;
                    transform: scale(1.0);
                }
            };
        }
    }

    .pagetop{
        width: 60px;
        height: 60px;
        background-color: #323233;
        position: fixed;
        bottom: 35px;
        right: 62px;
        display: none;
        cursor: pointer;
        @include SP{
            width: 50px;
            height: 50px;
            bottom: 33px;
            right: 0;
        };

        &:after{
            content: '\f341';
            color: #FFF;
            font-family : 'Font Awesome 5 Free';
            font-weight: 300;
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }

        &:hover{
            border-bottom: none;
            opacity: .7;
        }
    }
}
